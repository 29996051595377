import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
console.log('Bootstrap file loaded and made global');

//This is our main site.js
import './category_svg_hover.js';

//Bootstrap nav modifications for mobile
import './bootstrap_nav_mods.js';

// Other imports or code can go here
console.log('Main index (site.js) file loaded');


// Your custom scripts here
console.log('Webpack setup complete!');
