import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;


//This is our main site.js
import './category_svg_hover.js';

//Bootstrap nav modifications for mobile
import './bootstrap_nav_mods.js';


//aligning images when there is a left sidebar
import './mast_align_images.js';

//Now import our interactive map block JS
import '../blocks/interactive-map-block/interactive-map-block.js';

// // Other imports or code can go here
// console.log('Main index (site.js) file loaded');


// // Your custom scripts here
// console.log('Webpack setup complete!');
