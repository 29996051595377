//Variables that we will use in the listener. Sorta global-ish, but at same time let so scope should be contained to here
let interativeMapSnapSvgObject;

// Keeping track of tabs (maybe can move this to acf or make it dynamic later)
let counties = [];

function openTabById(tabId) {
  // Get the tab element
  let tabButton = document.getElementById(tabId);

  if (tabButton) {
    // Trigger Bootstrap's tab show functionality
    let bootstrapTab = new bootstrap.Tab(tabButton);
    bootstrapTab.show(); // This will trigger the tab change

    // Handle muting/unmuting of the background colors
    document.querySelectorAll("#countyTabs .nav-link").forEach((tabButton) => {
      let originalColor = window
        .getComputedStyle(tabButton)
        .getPropertyValue("background-color");

      // Check if the tab is the active one
      if (tabButton.id === tabId) {
        tabButton.classList.add("active");
        tabButton.style.backgroundColor = adjustRgbaAlpha(originalColor, 1); // Full opacity for active tab
      } else {
        tabButton.classList.remove("active");
        tabButton.style.backgroundColor = adjustRgbaAlpha(originalColor, 0.5); // Muted opacity for inactive tabs
      }
    });
  }
}

// Function to get the center point of the element for scaling
function getCenterOfElement(el) {
  var bbox = el.getBBox();
  var cx = bbox.x + bbox.width / 2;
  var cy = bbox.y + bbox.height / 2;
  return { cx, cy };
}

// Helper function to convert hex color to RGBA
function hexToRgba(hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Helper function to adjust RGBA/ RGB colors
function adjustRgbaAlpha(color, alpha) {
  if (color.startsWith("rgba")) {
    return color.replace(
      /rgba\((\d+), (\d+), (\d+), [^)]+\)/,
      `rgba($1, $2, $3, ${alpha})`
    );
  } else if (color.startsWith("rgb")) {
    return color.replace(
      /rgb\((\d+), (\d+), (\d+)\)/,
      `rgba($1, $2, $3, ${alpha})`
    );
  } else {
    return hexToRgba(color, alpha); // Fall back to hex conversion
  }
}

// Helper function to remove alpha channel from RGBA color
function removeAlphaFromRgba(color) {
  if (color.startsWith("rgba")) {
    return color.replace(
      /rgba\((\d+), (\d+), (\d+), [^)]+\)/,
      `rgb($1, $2, $3)`
    );
  }
  return color; // If it's already rgb or another format, return as is
}

function animateCountyGrow(county_svg_id) {
  let element = interativeMapSnapSvgObject.select("#" + county_svg_id);
  element.appendTo(interativeMapSnapSvgObject);
  const center = getCenterOfElement(element);
  element.animate(
    { transform: `s1.05,${center.cx},${center.cy}` },
    100,
    mina.easeinout
  );
  blurOtherCounties(county_svg_id);
}

function animateCountyShrink(county_svg_id) {
  let element = interativeMapSnapSvgObject.select("#" + county_svg_id);
  const center = getCenterOfElement(element);
  element.animate(
    { transform: `s1,${center.cx},${center.cy}` },
    100,
    mina.easeinout
  );
  unBlurCounties(county_svg_id);
}

// This applies hover effect to the map county svg.. not sure if it needed to be a function
function applyHoverEffect(county_svg_id) {
  // Define separate functions for mouseenter and mouseleave
  function onMouseEnter() {
    animateCountyGrow(county_svg_id);
  }

  function onMouseLeave() {
    animateCountyShrink(county_svg_id);
  }

  // Use Snap.svg's hover method, passing in the separate functions
  let element = interativeMapSnapSvgObject.select("#" + county_svg_id);
  element.hover(onMouseEnter, onMouseLeave);
}

//This adds the ability to click any of the tabs
function applyClickEffect(county_svg_id, tabname) {
  var element = interativeMapSnapSvgObject.select("#" + county_svg_id);

  // Add an onClick event to the element
  element.click(function () {
    // Perform your desired action when the element is clicked
    openTabById(tabname);

    // You can also trigger any other JavaScript logic here, like changing color
    this.animate({ fill: "#ff0000" }, 500, mina.easeinout);
  });
}

// //This gets the ball rolling for the SVG county map hover, and applies click effect
//REMOVING THIS METHOD AS NOW EMBEDDING THE SVG INLINE. Makes it a bit harder to update as you have to use Custom HTML
//But the <object> method caused problems with it reloading when tabs were changed
// function setupSvgHover() {
//   // Poll for the SVG to be loaded inside the <object> element
//   var checkSVGLoaded = setInterval(function () {
//     var objectElement = document.getElementById("full-county-map");
//     if (objectElement.contentDocument) {
//       // Get the SVG document inside the <object>
//       svgDoc = objectElement.contentDocument;

//       // Now use Snap.svg to target the first <svg> element inside the object
//       fullMap = Snap(svgDoc.querySelector("svg")); // Select the <svg> element

//       counties.forEach(({ name, slug, county_svg_id, tabname }) => {
//         // Select the element inside the SVG by its id (using the name as the id in this case)

//         applyHoverEffect(county_svg_id);

//         applyClickEffect(county_svg_id, tabname);
//       });

//       // Once the SVG is loaded, clear the interval
//       clearInterval(checkSVGLoaded);
//     }
//   }, 100); // Check every 100ms
// }

function setupSvgHover() {

    let fullCountyMapElement = document.querySelector(".full-county-map");

    if (fullCountyMapElement) {

      interativeMapSnapSvgObject = Snap(fullCountyMapElement.querySelector("svg"));

      counties.forEach(({ name, slug, county_svg_id, tabname }) => {
        // Select the element inside the SVG by its id (using the name as the id in this case)

        applyHoverEffect(county_svg_id);

        applyClickEffect(county_svg_id, tabname);
      });

    }

}

// Function to fade out all counties except the active one by lowering opacity
function blurOtherCounties(activeCountyId) {
  counties.forEach(({ county_svg_id }) => {
    // Skip the active county
    if (county_svg_id !== activeCountyId) {
      // Select the county element inside the SVG
      const element = interativeMapSnapSvgObject.select(`#${county_svg_id}`);

      if (element) {
        // Reduce opacity to make it appear faded
        element.animate({ opacity: 0.3 }, 100, mina.easeinout); // Adjust opacity as needed
      }
    }
  });
}

// Function to reset opacity of all counties
function unBlurCounties() {
  counties.forEach(({ county_svg_id }) => {
    // Select the county element inside the SVG
    const element = interativeMapSnapSvgObject.select(`#${county_svg_id}`);

    if (element) {
      // Restore opacity to full
      element.animate({ opacity: 1 }, 100, mina.easeinout);
    }
  });
}

window.blurOtherTabs = function (slug) {
  document.querySelectorAll("#countyTabs .nav-link").forEach((tabButton) => {
    const originalColor = window
      .getComputedStyle(tabButton)
      .getPropertyValue("background-color");

    if (tabButton.id != slug + "-tab") {
      // Adjust the color to be muted (reduce opacity)
      tabButton.style.backgroundColor = adjustRgbaAlpha(originalColor, 0.5);
    } else {
      tabButton.style.backgroundColor = removeAlphaFromRgba(originalColor);
    }
  });
};

/*
  //BOOTSTRAP TAB STUFF STARTS HERE
  */
// Handle manually added Home tab
const homeTab = document.getElementById("home-tab");
if (homeTab) {
  homeTab.addEventListener("click", function () {
    // Remove active state from all dynamically created tabs

    // Reset the background color of all dynamically created tabs from the DOM
    document.querySelectorAll("#countyTabs .nav-link").forEach((tabButton) => {
      const originalColor = window
        .getComputedStyle(tabButton)
        .getPropertyValue("background-color");

      // Adjust the color to be muted (reduce opacity)
      tabButton.style.backgroundColor = adjustRgbaAlpha(originalColor, 0.5);
    });

    // Set Home tab as active and its background color
    homeTab.style.backgroundColor = "var(--wp--preset--color--deep-lake-blue)"; // Adjust this to match your home tab's active background color

    //revert the SVG map to all unhover state incase.
  });

  // Now Fetch all the county map-tab data from WordPress REST API
  fetch(
    "/wp-json/wp/v2/map-tab?_acf=true&acf_format=standard&_fields=id,title,acf&orderby=menu_order&order=asc"
  )
    .then((response) => response.json())
    .then((tabs) => {
      const tabList = document.getElementById("countyTabs");
      const tabContent = document.getElementById("countyTabsContent");

      tabs.forEach((tab) => {
        const countyId = tab.acf.county_association[0];
        const slug = tab.title.rendered.toLowerCase().replace(/\s+/g, "-");
        const tabName = tab.acf.tab_name;
        const countySvgID = tab.acf.svg_id;
        const tabBackgroundColor = tab.acf.tab_background_color;
        const mobileBackgroundImage = tab.acf.mobile_background_image;
        const description = tab.acf.description;
        const mapImage = tab.acf.map_image;
        const additionalLinks = tab.acf.additional_links;

        // If the tab background color is already in rgba/rgb, use it directly
        const mutedBackgroundColor = adjustRgbaAlpha(tabBackgroundColor, 0.5);

        //BUILD up array of tab information so we can add hover effect on home map, and tab effect later
        counties.push({
          name: tabName,
          slug: slug,
          county_svg_id: countySvgID,
          tabname: slug + "-tab",
        });

        // Add tab
        const tabItem = document.createElement("li");
        tabItem.classList.add("nav-item");
        tabItem.role = "presentation";
        tabItem.innerHTML = `
          <button class="nav-link" id="${slug}-tab" data-bs-toggle="tab" data-bs-target="#${slug}" type="button" role="tab" aria-controls="${slug}" aria-selected="false" style="background-color: ${tabBackgroundColor};">${tabName}</button>
        `;
        tabList.appendChild(tabItem);

        // Add tab pane content
        const pane = document.createElement("div");
        pane.classList.add("tab-pane", "fade");
        pane.id = slug;
        pane.role = "tabpanel";
        pane.setAttribute("aria-labelledby", `${slug}-tab`);

        pane.innerHTML = `
        <!-- New county section -->
        <div class="county-content" id="${slug}-countyInfo">
            <div class="county-name desktop-left-col">
              <img src="${mobileBackgroundImage}" class="mobile-background-image">
              <h3 class="county-tab-heading has-deep-lake-blue-color has-averia-libre-font-family mt-0">
                ${tabName}
              </h3>
            </div>
            
            <div class="backbutton">
              <img src="./wp-content/themes/accelmwp/img/icons/back-icon.svg" class="back-to-home"  >
            </div>
            <div class="listing-description desktop-left-col responsive-text is-style-f5">
                <p class="county-description">${description}</p>
                <div id="${slug}-listings" class="desktop-left-col"><p>Loading listings...</p></div>
            </div>
            <div class="additionallinks" id="${slug}-additional-links">
            ${additionalLinks
              .map(
                (link, index) => `
                  <span>
                    ${index > 0 ? "&nbsp;|&nbsp;" : ""}<a href="${
                  link.additional_link.url
                }" target="${link.additional_link.target}">${
                  link.additional_link.title
                }</a>
                  </span>`
              )
              .join("")}      
            </div>
            <div class="map">
                <img class="map-svg" src="${mapImage}">
            </div>
        </div>

        <!-- New Listing Information section -->
        <div class="map-content" id="${slug}-listingInformation" style="display: none;">
            <div class="county-name desktop-left-col"><h2 id="${slug}-listingCountyName" class="has-deep-lake-blue-color has-averia-libre-font-family"></h2></div>
            <div class="map">
                <img id="${slug}-mapImage" src="" class="map-svg">
            </div>
            <div class="listing-name desktop-left-col"><h6 id="${slug}-listingName"></h6></div>
            <div class="listing-description desktop-left-col responsive-text is-style-f5">
                <p id="${slug}-listingDescription"></p> 
            </div>
            <div class="listing-image">
                <img id="${slug}-featuredImage" src="" alt="Featured Image">
                <div id="${slug}-desktopImageBackBtn" class="desktopImageBackBtn">x</div>
            </div>
            <div class="learnmore wp-block-button is-style-primary-button"><a id="${slug}-learnMoreLink" href="#" class="btn wp-block-button__link wp-element-button">More&nbsp;&gt;</a></div>
            <div class="backbutton">
                <div id="${slug}-backToListings">
                    <div class="backToListingMobile wp-block-button is-style-secondary-button">
                      <a class="wp-block-button__link wp-element-button">&lt;&nbsp;Back</a>
                    </div>
                    <div class="backToListingDesktop" ><img src="./wp-content/themes/accelmwp/img/icons/back-icon.svg" class="back-to-county"  ></div>
                </div>
            </div>
            <div class="additionallinks" id="${slug}-additional-links">
                ${additionalLinks
                  .map(
                    (link, index) => `
                      <span>
                        ${index > 0 ? "&nbsp;|&nbsp;" : ""}<a href="${
                      link.additional_link.url
                    }" target="${link.additional_link.target}">${
                      link.additional_link.title
                    }</a>
                      </span>`
                  )
                  .join("")}              
            </div>
        </div>
        `;
        tabContent.appendChild(pane);

        // Attach event listener to update background color on tab click
        const tabButton = document.getElementById(`${slug}-tab`);
        tabButton.addEventListener("click", function () {
          blurOtherTabs(slug);
        });
        //console.log(tabs);

        // Add hover functionality to switch to the active color when hovered
        tabButton.addEventListener("mouseenter", function () {
          animateCountyGrow(countySvgID);
          //blurOtherTabs(slug);
        });

        tabButton.addEventListener("mouseleave", function () {
          animateCountyShrink(countySvgID);
          //blurOtherTabs(slug);
        });

        function createBackToListingListener(id) {
          // Attach event listener to the back link

          document
            .getElementById(id)
            .addEventListener("click", function (event) {
              event.preventDefault();
              const countyInfo = document.getElementById(`${slug}-countyInfo`);
              const listingInfoSection = document.getElementById(
                `${slug}-listingInformation`
              );

              // Hide county info and show listing info with fade effect
              listingInfoSection.classList.remove("show");
              listingInfoSection.classList.add("fade");
              setTimeout(() => {
                listingInfoSection.style.display = "none";
                countyInfo.classList.add("fade", "show");
                countyInfo.style.display = "grid";
              }, 150); // Adjust the timeout duration based on your fade transition time
            });
        }

        createBackToListingListener(`${slug}-backToListings`);
        createBackToListingListener(`${slug}-desktopImageBackBtn`);
      }); //DONE SETTING UP TABS NOW!

      //Now that all tabs have been added lets go ahead and set up the hover stuff on the home map
      setupSvgHover(counties);

      // Add click event listener to all ".back-to-home" elements
      document.querySelectorAll(".back-to-home").forEach(function (element) {
        element.addEventListener("click", function () {
          openTabById("home-tab");
        });
      });

      // Fetch data for listings
      fetch(
        "/wp-json/wp/v2/listing?_acf=true&acf_format=standard&per_page=100&_fields=id,link,name,county,acf&show_in_interactive_map=true"
      )
        .then((response) => response.json())
        .then((listings) => {
          tabs.forEach((tab) => {
            const countyId = tab.acf.county_association[0];
            const slug = tab.title.rendered.toLowerCase().replace(/\s+/g, "-");
            const listingsContainer = document.getElementById(
              `${slug}-listings`
            );

            // Clear loading message
            listingsContainer.innerHTML = "";

            // Filter listings by the county and show_in_interactive_map flag
            const countyListings = listings.filter((listing) =>
              listing.county.includes(countyId)
            );

            if (countyListings.length > 0) {
              const ul = document.createElement("ul");
              countyListings.forEach((listing) => {
                const li = document.createElement("li");

                let imageid = "";
                const image = listing.acf["logo-featured_image"]; // Fetch the ACF image field from the API response

                if (image) {
                  // Check if the image exists
                  imageid = image["id"]; // Assign the image URL if available
                }
                //console.log(imageid);

                li.innerHTML = `<div class="is-style-text-cta"><a href="#" class="responsive-text listing-link" data-listing-id="${listing.id}" data-featured-media="${imageid}" data-county-slug="${slug}" data-county-name="${tab.acf.tab_name}">${listing.acf.name}&nbsp;<span class="gt-arrow">&gt;</span></a></div>`;
                ul.appendChild(li);
              });
              listingsContainer.appendChild(ul);
            } else {
              listingsContainer.innerHTML =
                "<p>No listings available for this county.</p>";
            }
          });

          // Add click event listeners to listing links
          document.querySelectorAll(".listing-link").forEach((link) => {
            link.addEventListener("click", function (event) {
              event.preventDefault();
              const listingId = this.getAttribute("data-listing-id");
              const featuredMediaId = this.getAttribute("data-featured-media");
              const countySlug = this.getAttribute("data-county-slug");
              const countyName = this.getAttribute("data-county-name");
              const listing = listings.find((l) => l.id == listingId);

              if (listing) {
                const countyInfo = document.getElementById(
                  `${countySlug}-countyInfo`
                );
                const listingInfoSection = document.getElementById(
                  `${countySlug}-listingInformation`
                );
                const countyNameElement = document.getElementById(
                  `${countySlug}-listingCountyName`
                );
                const listingNameElement = document.getElementById(
                  `${countySlug}-listingName`
                );
                const listingDescriptionElement = document.getElementById(
                  `${countySlug}-listingDescription`
                );
                const learnMoreLinkElement = document.getElementById(
                  `${countySlug}-learnMoreLink`
                );
                const featuredImageElement = document.getElementById(
                  `${countySlug}-featuredImage`
                );
                const mapImageElement = document.getElementById(
                  `${countySlug}-mapImage`
                );

                // Hide county info and show listing info with fade effect
                countyInfo.classList.remove("show");
                countyInfo.classList.add("fade");
                setTimeout(() => {
                  countyInfo.style.display = "none";
                  listingInfoSection.classList.add("fade", "show");
                  listingInfoSection.style.display = "grid";
                }, 150); // Adjust the timeout duration based on your fade transition time

                // Populate listing information
                countyNameElement.textContent = countyName;
                listingNameElement.textContent = listing.acf.name;
                listingDescriptionElement.textContent =
                  listing.acf.map_description;
                learnMoreLinkElement.href = listing.link;

                // Clear the old featured image for now so it can load properly!
                featuredImageElement.style.display = "none";

                // Display the featured image if it exists
                if (featuredMediaId) {
                  fetch(`/wp-json/wp/v2/media/${featuredMediaId}`)
                    .then((response) => response.json())
                    .then((media) => {
                      if (media && media.source_url) {
                        featuredImageElement.src = media.source_url;
                        featuredImageElement.style.display = "block";
                      } else {
                        featuredImageElement.style.display = "none";
                      }
                    })
                    .catch((error) => {
                      console.error("Error fetching featured image:", error);
                    });
                }

                // Display the map image if it exists
                if (listing.acf.map_image) {
                  mapImageElement.src = listing.acf.map_image;
                  mapImageElement.style.display = "block";
                } else {
                  mapImageElement.style.display = "none";
                }
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching listings:", error);
        });
    })
    .catch((error) => {
      console.error("Error fetching map-tab data:", error);
    });
}
