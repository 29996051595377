function alignImage(overlayOffset) {
  const textElement = document.querySelector("#listing-heading"); // Target the text element
  const mastElement = document.querySelector("#listing-mast"); // Target the text element
  const imageContainer = document.querySelector("#listing-image-container"); // Target the image container
  const leftMetaContentContainer = document.querySelector("#left-meta-content");

  // Get the position of the top left corner of the text element
  const textPosition = textElement.getBoundingClientRect();
  const mastPosition = mastElement.getBoundingClientRect();
  const imagePosition = imageContainer.getBoundingClientRect();

  let imageContainerTopRelative = textPosition.top - mastPosition.top;

  // Calculate the position to place the mast image, considering the PHP offset
  imageContainer.style.top = `${imageContainerTopRelative + overlayOffset}px`;

  if (leftMetaContentContainer) {
    const leftMetaPosition = leftMetaContentContainer.getBoundingClientRect();

    amountToMoveLeftMetaInfo = imagePosition.bottom - leftMetaPosition.top;
    amountToMoveLeftMetaInfo += 20;
    if (amountToMoveLeftMetaInfo < 20) {
      amountToMoveLeftMetaInfo = 20;
    }
    leftMetaContentContainer.style.paddingTop = amountToMoveLeftMetaInfo + "px";
  }
}

// Expose it globally so it can be called in inline script tags
window.alignImage = alignImage;
