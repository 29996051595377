  //STUFF for bootstrap 5 nav menus to toggle functionaity from hover to click depending on screensize
    // Select all dropdown elements
    var dropdowns = document.querySelectorAll('.navbar .dropdown');

    dropdowns.forEach(function(dropdown) {
        // On mouseenter, add Bootstrap's show class and aria-expanded="true"
        dropdown.addEventListener('mouseenter', function() {
            if (window.innerWidth >= 768) {  // Only apply on desktop screens
                var menu = this.querySelector('.dropdown-menu');
                var toggle = this.querySelector('.dropdown-toggle');
                menu.classList.add('show');
                dropdown.classList.add('show');
                toggle.setAttribute('aria-expanded', 'true');
                menu.setAttribute('data-bs-popper', 'static');
            }
        });
    
        // On mouseleave, remove the show class and set aria-expanded="false"
        dropdown.addEventListener('mouseleave', function() {
            if (window.innerWidth >= 768) {  // Only apply on desktop screens
                var menu = this.querySelector('.dropdown-menu');
                var toggle = this.querySelector('.dropdown-toggle');
                menu.classList.remove('show');
                dropdown.classList.remove('show');
                toggle.setAttribute('aria-expanded', 'false');
                menu.removeAttribute('data-bs-popper');
            }
        });

        // Allow navigation to the href on click for desktop screens
        dropdown.querySelector('.dropdown-toggle').addEventListener('click', function(event) {
            if (window.innerWidth >= 768) {
                event.stopPropagation();  // Prevents Bootstrap from toggling the dropdown
                window.location.href = this.href;  // Navigate to the link's href
            } else {
                // Prevent link from navigating on mobile to allow default Bootstrap dropdown behavior
                event.preventDefault();
            }
        });

    });