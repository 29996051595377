/*
    This adds hover effect to icons added by users by uploading two icons hence
    the somewhat unusal implementation to this. Perhaps we just need this on the 
    "activities page", but resumably if we apply this technique to other areas 
    of the site this could be handy to keep here as its pretty small
*/


document.addEventListener("DOMContentLoaded", function () {

    
  // Find all elements with the class .category-link for hover behavior
  var categoryLinks = document.querySelectorAll(".category-link");

  categoryLinks.forEach(function (link) {
    //console.log("icon found");
    var img = link.querySelector(".category-icon");
    if (!img) return; // If there's no .category-icon, skip this link

    var defaultSrc = img.getAttribute("data-default");
    var hoverSrc = img.getAttribute("data-hover");

    // Hover over both image and link text triggers the icon hover effect
    link.addEventListener("mouseover", function () {
      if (hoverSrc) {
        console.log("hovering");
        img.setAttribute("src", hoverSrc);
      }
    });

    // Revert back to default image when the mouse leaves
    link.addEventListener("mouseout", function () {
      if (defaultSrc) {
        img.setAttribute("src", defaultSrc);
      }
    });
  });

  // New Loop: Preload hover images for all .category-icon elements
  var categoryIcons = document.querySelectorAll(".category-icon");

  categoryIcons.forEach(function (img) {
    var hoverSrc = img.getAttribute("data-hover");

    // Preload hover image if data-hover is set and not empty
    if (hoverSrc && hoverSrc !== "") {
      var preloadImg = new Image();
      preloadImg.src = hoverSrc;
    }
  });
});

//This should probably be moved as well eventually as not all pages need this

function handleTaxonomyCheckboxes(taxonomy) {
  // Get all checkboxes for the given taxonomy
  const checkboxes = document.querySelectorAll(`.${taxonomy}-checkbox`);

  // Determine the URL parameter name (e.g., 'tag' for 'post_tag' and 'cat' for 'category')
  let urlParam =
    taxonomy === "post_tag"
      ? "tag"
      : taxonomy === "category"
      ? "cat"
      : taxonomy;

  checkboxes.forEach(function (checkbox) {
    checkbox.addEventListener("change", function () {
      let url = new URL(window.location.href);
      let params = url.searchParams;

      // Get the existing values for this taxonomy from the URL
      let taxonomyValues = params.get(urlParam);

      // Split the existing values into an array (comma separated)
      let valuesArray = taxonomyValues ? taxonomyValues.split(",") : [];

      // If the checkbox is checked, add its value (ID) to the array
      if (this.checked) {
        if (!valuesArray.includes(this.value)) {
          valuesArray.push(this.value); // Add the category ID
        }
      } else {
        // If unchecked, remove its value (ID) from the array
        valuesArray = valuesArray.filter((value) => value !== this.value);
      }

      // Update the URL with the new values for the taxonomy
      if (valuesArray.length > 0) {
        params.set(urlParam, valuesArray.join(","));
      } else {
        params.delete(urlParam); // Remove the parameter if no values are selected
      }

      // Update the URL
      window.history.replaceState({}, "", url);

      // Reload the page after updating the URL
      window.location.reload();
    });
  });
	
}


// Expose it globally so it can be called in inline script tags
window.handleTaxonomyCheckboxes = handleTaxonomyCheckboxes;
